import React from 'react';
import logo from '../../images/fuku.png';
import './css/failure.css'; // Archivo CSS para estilos

const Failure = () => {
  // Simular datos o capturarlos desde la URL
  const paymentId = "123456789"; // Reemplaza con un valor dinámico
  const status = "rejected"; // Estado de pago rechazado
  const preferenceId = "abcdef12345";

  // Deep Link para redirigir a la app móvil
  const deepLink = `myapp://failure?payment_id=${paymentId}&status=${status}&preference_id=${preferenceId}`;

  // URL de respaldo si la app no está instalada
  const fallbackUrl = `https://example.com/failure?payment_id=${paymentId}&status=${status}&preference_id=${preferenceId}`;

  const handleDeepLink = () => {
    // Intentar redirigir al Deep Link
    window.location.href = deepLink;

    // Redirigir a la URL de respaldo tras 2 segundos si no se abre la app
    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 2000);
  };

  return (
    <div className="failure-container">
      <div className="failure-logo">
        <img src={logo} alt="Fukutsu Logo" />
      </div>
      <h1 className="failure-title">¡Pago Rechazado!</h1>
      <p className="failure-message">Lo sentimos, el pago no pudo ser procesado.</p>
      <div className="failure-details">
        <ul>
          <li>
            <strong>Payment ID:</strong> {paymentId}
          </li>
          <li>
            <strong>Status:</strong> {status}
          </li>
          <li>
            <strong>Preference ID:</strong> {preferenceId}
          </li>
        </ul>
      </div>
      <button className="deep-link-button" onClick={handleDeepLink}>
        Volver a Fukutsu
      </button>
    </div>
  );
};

export default Failure;
