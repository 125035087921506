import React from 'react';
import logo from '../../images/fuku.png'; // Cambia esto según la ubicación de tu logo
import './css/pending.css'; // Archivo CSS para estilos

const Pending = () => {
  const paymentId = "123456789"; // Reemplaza con un valor dinámico si lo deseas
  const status = "pending";
  const preferenceId = "abcdef12345";

  // Deep Link para redirigir a la app móvil
  const deepLink = `myapp://pending?payment_id=${paymentId}&status=${status}&preference_id=${preferenceId}`;

  // URL de respaldo si la app no está instalada
  const fallbackUrl = `https://example.com/pending?payment_id=${paymentId}&status=${status}&preference_id=${preferenceId}`;

  const handleDeepLink = () => {
    // Intentar redirigir al Deep Link
    window.location.href = deepLink;

    // Redirigir a la URL de respaldo tras 2 segundos si no se abre la app
    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 2000);
  };

  return (
    <div className="pending-container">
      <div className="pending-logo">
        <img src={logo} alt="Fukutsu Logo" />
      </div>
      <h1 className="pending-title">¡Pago Pendiente!</h1>
      <p className="pending-message">Tu pago está siendo procesado. Por favor, espera la confirmación.</p>
      <div className="pending-details">
        <ul>
          <li>
            <strong>Payment ID:</strong> {paymentId}
          </li>
          <li>
            <strong>Status:</strong> {status}
          </li>
          <li>
            <strong>Preference ID:</strong> {preferenceId}
          </li>
        </ul>
      </div>
      <button className="deep-link-button" onClick={handleDeepLink}>
        Volver a Fukutsu
      </button>
    </div>
  );
};

export default Pending;
