import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Payment = () => {
  const { sandboxUrl } = useParams(); // Obtener la URL de sandbox desde la URL

  useEffect(() => {
    if (sandboxUrl) {
      // Redirige a la URL de sandbox proporcionada
      window.location.href = decodeURIComponent(sandboxUrl);
    }
  }, [sandboxUrl]);

  return (
    <div>
      <h1>Redirigiendo a Mercado Pago...</h1>
      <p>Por favor, espere mientras lo llevamos al checkout.</p>
    </div>
  );
};

export default Payment;
